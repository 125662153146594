import * as Yup from "yup";

import { createForm } from "../../forms";

export type TravelNoteFormFields = {
  endDate: DateString | null;
  startDate: DateString | null;
  travelDestinationCodes: Record<string, string> | null;
};

const validationSchema = Yup.object({
  endDate: Yup.string()
    .required("Must pick an end date.")
    .nonNullable("Must pick an end date."),
  startDate: Yup.string()
    .required("Must pick a start date.")
    .nonNullable("Must pick a start date."),
  travelDestinationCodes: Yup.object().nonNullable(
    "Must pick a travel destination",
  ),
});

const initialValues: TravelNoteFormFields = {
  endDate: null,
  startDate: null,
  travelDestinationCodes: null,
};

export const createTravelNoteForm = createForm({
  initialValues,
  validationSchema,
});
